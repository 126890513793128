import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Digital Wines Club",
  keyword:
    "vino, wine, club de vino, wine club, nft, blockchain, crypto, crypto collectibles, cryptocurrency, digital items, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset",
  desc: "La primera comunidad de vino blockchain (NFT) del mundo!",
};

export default Meta;
