const footerMenuList = [
  {
    id: 1,
    title: "Buscanos en las redes",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "https://whitepaper.digitalwinesclub.com",
        text: "Whitepaper",
      },
    ],
  },/*
  {
    id: 2,
    title: "Nosotros",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Explora",
      },
    ],
  },
  {
    id: 3,
    title: "Mi cuenta",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "#",
        text: "Próximamente",
      },
    ],
  },*/
];

const socialIcons = [

  {
    id: 1,
    href: "https://twitter.com/dwinesclub",
    text: "twitter",
  },
  {
    id: 2,
    href: "https://www.instagram.com/digitalwinesclub/",
    text: "instagram",
  },

];

export { footerMenuList, socialIcons };
